<template>
    <div class="key_point main_padding">
      <div class="top_text w1400">
        <h2 class="h1 border_title txt-right main_padding-b">
          <span class="txt-bold">BASIC PLAN</span>
        </h2>
        <h5 class="sub_title txt-bold">
          掌握當前課題，設定目標
        </h5>
        <h6 class="txt-bold">
          GRASP CURRENT ISSUES AND SET GOALS
        </h6>
        <p>
          對於食品及醫療的「安心·安全」意識逐年高漲，
          食品及醫藥品廠商不僅要遵守法律，也會被嚴格要求衛生層面以及製造流程的管理制度，針對地震等等的天災應對措施，要及時響應各類消費者的需求，並於食品及醫藥品工廠建設時，進行多方面考量。
          <br>
          <br>
          新產品的開發、生產量的增加以及因設備劣化而新設工廠或重組工廠，
          又或是由於BCP(企業永續運作計畫)造成工廠搬遷或是分散化等等的工廠計畫，這些可說是掌握未來公司命運的一大項目，投資如此之大，建設完成後，是無法輕易重新開始的。
        </p>
      </div>

      <div class="steps">
        <div class="step1_block">
          <div class="bg" :style="{
            backgroundImage: `url(${require('@/assets/img/process/01_key_point/02_bg.jpg')})`
          }">
          </div>

          <div class="step1_inner w1400">
            <div class="ball">
              <div class="ball_in"></div>
            </div>
            <div class="line">
              <div
                class="line_in"
                :class="[{active: ifStep2}]"
                :style="[{height: '50%'}]">
              </div>
            </div>

            <h6 class="txt-bold txt-theme">
              STEP 1
            </h6>
            <h5 class="sub_title txt-bold txt-white">
              課題的掌握/目標設定
            </h5>
            <h6 class="txt-bold txt-theme" style="margin-top: 10px;">
              <span>
                <img src="@/assets/img/process/01_key_point/02_triangle.svg" alt="">
              </span>
              &nbsp;
              首先掌握現狀，再找出課題及問題點
            </h6>

            <div class="itembox">
              <div class="item">
                <img src="@/assets/img/process/01_key_point/step1_icon1.svg" alt="">
                <p>風險迴避</p>
              </div>
               <div class="item">
                <img src="@/assets/img/process/01_key_point/step1_icon2.svg" alt="">
                <p>提升生產</p>
              </div>
               <div class="item">
                <img src="@/assets/img/process/01_key_point/step1_icon3.svg" alt="">
                <p>生產設備與建築的和諧</p>
              </div>
               <div class="item">
                <img src="@/assets/img/process/01_key_point/step1_icon4.svg" alt="">
                <p>安心、安全的對應</p>
              </div>
               <div class="item">
                <img src="@/assets/img/process/01_key_point/step1_icon5.svg" alt="">
                <p>法令的遵守</p>
              </div>
            </div>

            <h6 class="txt-bold txt-theme">
              <span>
                <img src="@/assets/img/process/01_key_point/02_triangle.svg" alt="">
              </span>
              &nbsp;
              找出課題及問題點之後，設定未來目標
            </h6>

            <ul class="list txt-white">
              <li>
                <p>
                  <span>・</span>
                  確實掌握現狀
                </p>
              </li>
              <li>
                <p>
                  <span>・</span>
                  建立明確目標
                </p>
              </li>
              <li>
                <p>
                  <span>・</span>
                  建立未來展望計畫
                </p>
              </li>
              <li>
                <p>
                  <span>・</span>
                  掌握相關法令
                </p>
              </li>
            </ul>
          </div>

        </div>

        <div class="step2_block" ref="step2_block">
          <div class="bg" :style="{
            backgroundImage: `url(${require('@/assets/img/process/01_key_point/03_bg.svg')})`
          }">
          </div>
          <div class="step2_inner w1400">
            <div class="ball" :class="[{active: ifStep2}]">
              <div class="ball_in"></div>
            </div>
            <div class="line">
              <div
                class="line_in"
                :class="[{active: ifStep2}]"
                :style="[{height: '0%'}]">
              </div>
            </div>
            <div class="inner_in">
              <h6 class="txt-bold txt-theme">
                STEP 2
              </h6>
              <h5 class="sub_title txt-bold txt-white">
                基本計劃的重要性
              </h5>
              <p class="txt-white">
                <span class="txt-bold">
                  將STEP1所設定的目標具體化，我們認為最重要的是「企劃．基本計畫的製作階段」
                </span>
                <br><br>
                建立一個新工廠，需要執行諸多工程，我們認為最重要的工程就是 「企劃．基本計畫的製作階段」，
                首先是掌握現狀並找出問題點，與顧客合而為一，共同構想未來，成為新工廠的概念。
                初期投資一定會考量到機器開始運轉後的運行成本，思考生產設備是否能發揮最大產值，
                以及思考生產設備與建築是否和諧，而決定生產設備與建築和諧的關鍵就是「企劃．基本計畫」。
                不管是新建工廠還是翻新工廠，現狀與課題的分析以及確實設立目標，皆會影響專案執行的結果。
                我們並不認為「工廠完成=目標」，工廠的完工對顧客來說才是起點，因此，我們認為基本計畫中，
                「完工後」的階段是比「完工前」還要重要的。 麒麟技術不論是對於工廠生產效率還是產量，
                皆會思考具有未來性的「企劃．基本計畫」，成為顧客的最佳夥伴。
              </p>
              <!-- <div class="itembox">
                <div class="item">
                  <img src="@/assets/img/process/01_key_point/icon1.svg" alt="">
                  <div>
                    <p class="txt-gray">
                      01.
                    </p>
                    <div style="border-bottom: 1px solid #ccc"></div>
                    <p>
                      思考如何發揮最大產值
                    </p>
                  </div>
                </div>
                <div class="item">
                  <img src="@/assets/img/process/01_key_point/icon1.svg" alt="">
                  <div>
                    <p class="txt-gray">
                      02.
                    </p>
                    <div style="border-bottom: 1px solid #ccc"></div>
                    <p>
                      考量生產設備、建築、共用設備之間的平衡
                    </p>
                  </div>
                </div>
                <div class="item">
                  <img src="@/assets/img/process/01_key_point/icon1.svg" alt="">
                  <div>
                    <p class="txt-gray">
                      03.
                    </p>
                    <div style="border-bottom: 1px solid #ccc"></div>
                    <p>
                      確實掌握現況
                    </p>
                  </div>
                </div>
                <div class="item">
                  <img src="@/assets/img/process/01_key_point/icon1.svg" alt="">
                  <div>
                    <p class="txt-gray">
                      04.
                    </p>
                    <div style="border-bottom: 1px solid #ccc"></div>
                    <p>
                      計畫範籌涵蓋至完工後 10~20年
                    </p>
                  </div>
                </div>
              </div> -->
              <ul class="list txt-white">
                <li>
                  <p>
                    <span class="txt-theme">・</span>
                    思考如何發揮最大產值
                  </p>
                </li>
                <li>
                  <p>
                    <span class="txt-theme">・</span>
                    考量生產設備、建築、共用設備之間的平衡
                  </p>
                </li>
                <li>
                  <p>
                    <span class="txt-theme">・</span>
                    確實掌握現況
                  </p>
                </li>
                <li>
                  <p>
                    <span class="txt-theme">・</span>
                    計畫範籌涵蓋至完工後的10~20年
                  </p>
                </li>
            </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="process main_padding-t">
        <div class="w1200">
          <h5 class="sub_title txt-bold txt-center main_padding-b">工廠建設流程</h5>

          <img
            src="@/assets/img/process/01_key_point/step.png"
            style="max-width: 1000px; margin: auto; display: block;"
            alt="">
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { keypointProcess } from '@/utils/processPageData';

export default {
  name: 'Keypoint',
  components: {
  },
  data() {
    return {
      keypointProcess,
      keypointProcessNowIndex: 0,
      ifStep2: false,
    };
  },
  computed: {
    ...mapState([
      'scrollDistence',
    ]),
  },
  watch: {
    scrollDistence() {
      console.log(this.scrollDistence);
      console.log(this.$refs.step2_block.offsetTop);
      if (this.scrollDistence > this.$refs.step2_block.offsetTop - window.innerHeight / 1.5) {
        this.ifStep2 = true;
      } else {
        this.ifStep2 = false;
      }
    },
  },
  methods: {
    keypointProcessNext() {
      if (this.keypointProcessNowIndex < this.keypointProcess.length - 1) {
        this.keypointProcessNowIndex += 1;
      } else {
        this.keypointProcessNowIndex = 0;
      }
    },
  },
};
</script>
